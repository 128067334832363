<template>
  <div class="content-box">
    <div class="content-header">
      <el-select class="input" v-model="queryForm.isEnable" clearable placeholder="启禁状态">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input
        v-model="queryForm.serviceName"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入服务商名称"
      ></el-input>
      <el-input
        v-model="queryForm.phone"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入联系方式"
      ></el-input>
      <el-input
        v-model="queryForm.storeName"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入关联门店名称"
      ></el-input>
      <el-button type="plain" class="cotent-btn" @click="handleSearch">搜索</el-button>
      <el-button type="plain" class="cotent-btn-big" icon="el-icon-plus" @click="handleEdit">新增服务商</el-button>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
        v-loading="loading">
        <el-table-column prop="id" align="center" width="" label="序号">
        </el-table-column>
        <el-table-column prop="serviceProviderName" align="center" label="服务商名称">
        </el-table-column>
        <el-table-column prop="storeServiceOwners" align="center" label="负责人">
          <template #default="{row}">
            <p v-for="ssOwner in row.storeServiceOwners" :key="ssOwner.id">
              {{`${ssOwner.nickname}: ${ssOwner.phone ?? ""}`}}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="storeInfo" align="left" label="关联门店">
          <template #default="{row}">
            <p v-for="ssOwner in row.storeInfo" :key="ssOwner.id">
              {{ssOwner.storeName}}<span class="store-saas-name" v-if="ssOwner.storeSaaSId">(SaaS系统:{{ssOwner.storeSaaSName?.substring(0, 16)}})</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="remark" align="center" label="备注">
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="{row}">
            <el-button :class="{ 
              'disable-btn': row.enableState == serviceStatusEnum?.Enable,
              'enable-btn': row.enableState == serviceStatusEnum?.Disable,
              }"
             type="text" @click="handleUpdateStatus(row)">{{ row.enableState == serviceStatusEnum?.Enable ? "启用" : "禁用"}}</el-button>
            <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="460px" :before-close="dialogClose">
        <div class="edit-box">
          <el-form label-position="left" label-suffix=":" label-width="100px" :model="formData" :rules="formRules" ref="validateForm">
            <el-form-item label="服务商名称" prop="serviceProviderName">
              <el-input v-model="formData.serviceProviderName" class="dialogInput" placeholder="请输入服务商名称" maxlength="50">
              </el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-select
                clearable
                :value="undefined"
                @change="($event) => setCurUser($event, 'userInfo')"
                class="dialogInput"
                remote
                :remote-method="getUserList"
                @focus="getUserList"
                filterable
                placeholder="请输入负责人手机"
                :loading="userSelectLoading"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.id"
                  :label="item.phone"
                  :value="item"
                >
                <span>{{ `${item.nickname ?? ""}${item.nickname ? ":" : ""} ${ item.phone ?? "" }` }}</span>
                </el-option>
              </el-select>
              <el-tag
                class="tag"
                :key="tag.id"
                v-for="tag in userInfo"
                closable
                :disable-transitions="false"
                @close="handleClose(tag, 'userInfo')"
              >
                {{ `${tag.nickname}: ${tag.phone}` }}
              </el-tag>
            </el-form-item>
            <el-form-item label="关联门店" prop="storeInfo">
              <el-select
                clearable
                :value="undefined"
                @change="($event) => setCurUser($event, 'storeInfo')"
                class="dialogInput"
                remote
                :remote-method="getStoreList"
                @focus="getStoreList"
                @blur="validateField"
                filterable
                placeholder="请选择关联门店"
                :loading="storeSelectLoading"
              >
                <el-option
                  v-for="item in allStoreList"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item"
                >
                <span>{{item.storeName}}</span> <span class="ellipsis-text store-saas-name" v-if="item.saasStoreId">SaaS系统:{{item.saasStoreName}}</span>
                </el-option>
              </el-select>
              <el-tag
                class="tag"
                :key="tag.id"
                v-for="tag in storeInfo"
                closable
                :disable-transitions="false"
                @close="handleClose(tag, 'storeInfo')"
              >
                {{ tag.storeName }}<span class="ellipsis-text store-saas-name" v-if="tag.saasStoreId">(SaaS系统:{{tag.saasStoreName}})</span>
              </el-tag>
            </el-form-item>
            <el-form-item label="备注内容" prop="remark">
              <el-input v-model.number="formData.remark" class="dialogInput" type="textarea"
                placeholder="请输入备注内容" maxlength="150"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button :loading="saveLoading" type="primary" @click="dialogConfirm">提交</el-button>
          </div>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { getStoreServiceList, addStoreService, updateStoreService, updateStoreServiceStatus, userList } from "@/api/serviceProvider"

import { storeList } from "@/api/store";

// 工具
import { debounce, cloneDeep, uniqWith } from "lodash";

// 常量
import { ServiceStatusEnum } from "@/enum/service.enum";

@Component
export default class StoreList extends Mixins(loading, tablePagination, Resize) {
  serviceStatusEnum = ServiceStatusEnum

  queryForm = { // 查询参数
    serviceName: "",
    phone: "",
    storeName: ""
  }

  tableData = [];
  itemTotal = 0;

  dialogVisible = false;
  dialogTitle = "";
  
  formData = {
    id: "",
    serviceProviderName: "",
    storeInfo: [], // 这里先放个 prop 给form 的rule使用
    remark: "",
  };

  allStoreList = [];
  allUserList = [];
  userSelectLoading = false;
  userInfo = [];// 负责人手机数据
  storeSelectLoading = false;
  storeInfo = []; // 关联门店数据

  saveLoading = false;


  formRules = {
    serviceProviderName: [{ required: true, message: "请输入服务商名称", trigger: "blur" }],
    storeInfo: [{ required: true, validator: this.validateStoreInfo, trigger: "blur" }],
  }

  statusOptions = [{ label: "已启用", value: 1 }, { label: "已禁用", value: 0 }]


  mounted() {
    this.windowResize(310)
    this.getData();
  }

  // form 的validate 方法
  validateStoreInfo(rule, value, callback) {
    if (this.storeInfo?.length) return callback();
    return callback(new Error("请选择关联门店")) 
  }

  getData() {
    this.showLoading();
    getStoreServiceList({
      ...this.queryForm,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    }).then(res => {
      this.hideLoading();
      this.tableData = res.data.list;
      this.itemTotal = res.data.total;
    }).finally(() => {
      this.hideLoading();
    });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }
  // 新增/编辑
  handleEdit(row) {
    // 如果row 存在，则说明是编辑
    const isEdit = row.id;
    this.dialogTitle = isEdit ? "编辑服务商" : "新增服务商";
    this.dialogVisible = true;
    if (isEdit) {
      this.$nextTick(() => {
        // 最好放在里面，会有一种情况就是 resetFiled 的时候，不是初始值，是某次编辑的值
        this.storeInfo = cloneDeep(row.storeInfo)?.map((item) => ({...item, saasStoreId: item.storeSaaSId, saasStoreName: item.storeSaaSName, id: item.storeId})) // 这里把id 赋值回去（主要是列表返回的，和下拉数据字段不一样），在提交的时候，要用
        this.userInfo = cloneDeep(row.storeServiceOwners);
        const { id, serviceProviderName, remark } = row;
        Object.assign(this.formData, { id, serviceProviderName, remark })
      })
    }
  }

  /**
   * @description 删除二次弹窗
   */
   handleUpdateStatus(row) {
    const { enableState, serviceProviderName }  = row;
    this.$confirm(`是否${enableState == ServiceStatusEnum?.Enable ? "启用" : "禁用"}服务商[${serviceProviderName}]`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.doUpdateStatus(row);
    })
  }

  // 删除操作
  doUpdateStatus(row) {
    const params = { storeServiceId: row.id };
    if (row.enableState == ServiceStatusEnum?.Enable) {
      params.enableState = ServiceStatusEnum?.Disable;
    } else {
      params.enableState = ServiceStatusEnum?.Enable.toString();
    }
    updateStoreServiceStatus(params).then(() => {
      this.getData();
    })
  }

  getStoreRemote = debounce(function (str) {
      this.storeSelectLoading = true;
      storeList({storeName: str,pageNum: 1,pageSize: 500}).then((res) => {
        this.storeSelectLoading = false;
        this.allStoreList = res.data && res.data.list;
      }).catch((err) => {});
  })


  getStoreList(str) {
    if (typeof str == "object") {
      str = "";
    }
    this.getStoreRemote(str)
  }

  getUserRemote = debounce(function (str) {
    this.userSelectLoading = true;
    userList({phone: str,pageNum: 1,pageSize: 500})
      .then((res) => {
        this.userSelectLoading = false;
        this.allUserList = res.data || [];
      })
      .catch((err) => {});
  })

  getUserList(str) {
    if (typeof str == "object") {
      str = "";
    }
    this.getUserRemote(str);
  }
  setCurUser(cur, key) {
    this[key].push({...cur, userId: cur.id});
    const comparator = (obj1, obj2) => obj1.id === obj2.id;
    this[key] = uniqWith(this[key], comparator)
  }

  handleClose(cur, key) {
    this[key] = this[key].filter((item) => {
      return item.id != cur.id;
    });
  }

  dialogClose() {
    // 先把这两个列表清除掉，再执行 resetFields，因为这两兄弟被watch 了
    this.storeInfo = []; // 关联门店数据
    this.userInfo = [];// 负责人手机数据

    this.$nextTick(() => {
      this.$refs['validateForm'].resetFields();
      this.formData.id = "";
      this.dialogVisible = false;
      this.saveLoading = false;
    })
  }
  dialogConfirm() {
    this.$refs["validateForm"].validate().then(() => {
      const params = { 
        ...this.formData,
        storeInfo: cloneDeep(this.storeInfo)?.map((row) => ({ storeId: row.id, storeName: row.storeName, storeType: row.storeType, storeSaaSId: row.saasStoreId, storeSaaSName: row.saasStoreName })),
        userInfo: cloneDeep(this.userInfo)?.map((row) => ({id: row.id, 
          userId: row.userId, 
          nickname: row.nickname, phone: row.phone, storeServiceId: row.storeServiceId})),
      }
      this.saveLoading = true;
      const api = this.formData.id ? updateStoreService : addStoreService;
      api(params).then(() => {
        this.$message.success(`${this.formData.id ? '编辑' : '新增' }成功`);
        this.dialogClose();
        this.getData();
      }).finally(() => {
        this.saveLoading = false;
      })
    });

  }
  getName(target, id, keyId, keyName) {
    let name = ''
    target.forEach(item => {
      if (item[keyId] == id) {
        name = item[keyName]
      }
    })
    return name
  }

  @Watch('storeInfo')
  validateField(val, oldVal) {
    this.$refs["validateForm"].validateField(["storeInfo"])
  }

}
</script>



<style lang="scss" scoped src="./style.scss">
</style>